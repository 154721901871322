/* ALL PERMISSIONS */

export const ROLE_DATA = {
  OWNER: {
    value: "OWNER",
    title: "Owner",
    description: "Best for owners of the store with unrestricted controls",
    permissions: [
      {
        scope: "Dashboard",
        permissions: ["Can view analytics", "Can view Tasks", "Can view most viewed products"],
      },
      {
        scope: "Products",
        permissions: ["Can view products", "Can manage products"],
      },
      {
        scope: "Orders",
        permissions: ["Can view analytics", "Can view orders ", "Can manage orders"],
      },
      {
        scope: "Customers",
        permissions: ["Can view customers", "Can manage customers"],
      },
      {
        scope: "Subscriptions",
        permissions: ["Can view subscriptions", "Can manage subscriptions", "Can view payment history"],
      },
      {
        scope: "Settings",
        permissions: [
          "Can view store managers",
          "Can manage store managers",
          "Can manage store details",
          "Can manage store categories",
        ],
      },
    ],
  },
  ADMIN: {
    value: "ADMIN",
    title: "Admin",
    description: "Best for mangers with full control and little restrictions",
    permissions: [
      {
        scope: "Dashboard",
        permissions: ["Can view analytics", "Can view Tasks", "Can view most viewed products"],
      },
      {
        scope: "Products",
        permissions: ["Can view products", "Can manage products"],
      },
      {
        scope: "Orders",
        permissions: ["Can view analytics", "Can view orders ", "Can manage orders"],
      },
      {
        scope: "Customers",
        permissions: ["Can view customers", "Can manage customers"],
      },
      {
        scope: "Subscriptions",
        permissions: ["Can view subscription"],
      },
      {
        scope: "Settings",
        permissions: [
          "Can view store managers",
          "Can manage store managers",
          "Can manage store details",
          "Can manage store categories",
        ],
      },
    ],
  },
  OPERATOR: {
    value: "OPERATOR",
    title: "Operator",
    description: "Best for managers with basic control access",
    permissions: [
      {
        scope: "Dashboard",
        permissions: ["Can view analytics", "Can view Tasks", "Can view most viewed products"],
      },
      {
        scope: "Products",
        permissions: ["Can view products", "Can manage products"],
      },
      {
        scope: "Orders",
        permissions: ["Can view orders ", "Can manage orders"],
      },
      {
        scope: "Customers",
        permissions: ["Can view customers", "Can manage customers"],
      },
      {
        scope: "Subscriptions",
        permissions: ["Can view subscription"],
      },
      {
        scope: "Settings",
        permissions: ["Can view store managers", "Can manage store categories"],
      },
    ],
  },
};

const GENERAL_ACCESS = "GENERAL_ACCESS";

enum DASHBOARD {
  VIEW_ANALYTICS = "VIEW_DASHBOARD_ANALYTICS",
  VIEW_TASKS = "VIEW_DASHBOARD_TASKS",
  VIEW_MOST_VIEWED = "VIEW_MOST_VIEWED",
}

enum PRODUCTS {
  VIEW_PRODUCTS = "VIEW_PRODUCTS",
  CREATE_PRODUCTS = "CREATE_PRODUCTS",
  EDIT_PRODUCTS = "EDIT_PRODUCTS",
  FEATURE_PRODUCTS = "FEATURE_PRODUCTS",
}

enum ORDERS {
  VIEW_ANALYTICS = "VIEW_ORDER_ANALYTICS",
  VIEW_ORDERS = "VIEW_ORDERS",
  UPDATE_ORDERS = "UPDATE_ORDERS",
  RECORD_ORDERS = "RECORD_ORDERS",
  EXPORT_ORDERS = "EXPORT_ORDERS",
}

enum CUSTOMERS {
  VIEW_CUSTOMERS = "VIEW_CUSTOMERS",
  UPDATE_CUSTOMERS = "UPDATE_CUSTOMERS",
}

enum SUBSCRIPTIONS {
  VIEW_SUBSCRIPTION = "VIEW_SUBSCRIPTION",
  MANAGE_SUBSCRIPTIONS = "MANAGE_SUBSCRIPTIONS",
  VIEW_PAYMENT_HISTORY = "VIEW_PAYMENT_HISTORY",
}

enum SETTINGS {
  UPDATE_STORE_DETAILS = "UPDATE_STORE_DETAILS",
  MANAGE_PRODUCT_CATEGORIES = "MANAGE_PRODUCT_CATEGORIES",
  VIEW_TEAM_MEMBERS = "VIEW_TEAM_MEMBERS",
  MANAGE_TEAM_MEMBERS = "MANAGE_TEAM_MEMBERS",
  UPDATE_MAINTENANCE_MODE = "UPDATE_MAINTENANCE_MODE",
  UPDATE_SECURITY_PIN = "UPDATE_SECURITY_PIN",
  MANAGE_BRANCHES = "MANAGE_BRANCHES",
}

enum WALLETS {
  CAN_MANAGE_WALLET = "CAN_MANAGE_WALLET",
  CAN_VIEW_BALANCE = "CAN_VIEW_BALANCE",
}

enum PAYMENTS {
  CAN_VIEW_ANALYTICS = "CAN_VIEW_ANALYTICS",
}

enum PLAN_PERMISSIONS {
  CAN_MANAGE_ITEMS = "CAN_MANAGE_ITEMS",
  CAN_MANAGE_CATEGORIES = "CAN_MANAGE_CATEGORIES",
  CAN_OWN_MULTIPLE_STORES = "CAN_OWN_MULTIPLE_STORES",
  CAN_MANAGE_DISCOUNTS = "CAN_MANAGE_DISCOUNTS",
  CAN_CUSTOMIZE_LINK = "CAN_CUSTOMIZE_LINK",
  CAN_MANAGE_PRODUCT_OPTIONS = "CAN_MANAGE_PRODUCT_OPTIONS",
  CAN_MANAGE_COUPONS = "CAN_MANAGE_COUPONS",
  CAN_MANAGE_CURRENCIES = "CAN_MANAGE_CURRENCIES",
  CAN_OWN_TEAM = "CAN_OWN_TEAM",
  CAN_INSTAGRAM_IMPORT = "CAN_INSTAGRAM_IMPORT",
  CAN_CUSTOMIZE_COLORS = "CAN_CUSTOMIZE_COLORS",
  CAN_USE_CHOWBOT = "CAN_USE_CHOWBOT",
  CAN_OWN_BRANCHES = "CAN_OWN_BRANCHES",
  CAN_USE_MENU_UPLOADS = "CAN_USE_MENU_UPLOADS",
  CAN_USE_CHOWDECK = "CAN_USE_CHOWDECK",
  CAN_USE_FACEBOOK_PIXEL = "CAN_USE_FACEBOOK_PIXEL",
}

enum COUNTRY_PERMISSIONS {
  CAN_COLLECT_PAYMENTS = "CAN_COLLECT_PAYMENTS",
}

enum RECEIPTS {
  CAN_GENERATE_RECEIPTS = "CAN_GENERATE_RECEIPTS",
}

enum DELIVERIES {
  CAN_BOOK_DELIVERIES = "CAN_BOOK_DELIVERIES",
}

enum CHOWBOT {
  CAN_VIEW_TOKEN_BALANCE = "CAN_VIEW_TOKEN_BALANCE",
}

/* ROLES AND PERMISSIONS */

const OPERATOR = [
  DASHBOARD.VIEW_ANALYTICS,
  DASHBOARD.VIEW_MOST_VIEWED,
  DASHBOARD.VIEW_TASKS,
  PRODUCTS.VIEW_PRODUCTS,
  PRODUCTS.CREATE_PRODUCTS,
  PRODUCTS.EDIT_PRODUCTS,
  ORDERS.UPDATE_ORDERS,
  ORDERS.VIEW_ORDERS,
  CUSTOMERS.VIEW_CUSTOMERS,
  SUBSCRIPTIONS.VIEW_SUBSCRIPTION,
  SETTINGS.VIEW_TEAM_MEMBERS,
  SETTINGS.MANAGE_PRODUCT_CATEGORIES,
  CUSTOMERS.UPDATE_CUSTOMERS,
  GENERAL_ACCESS,
  SETTINGS.UPDATE_MAINTENANCE_MODE,
];

const ADMIN = [
  ...OPERATOR,
  SETTINGS.MANAGE_TEAM_MEMBERS,
  ORDERS.VIEW_ANALYTICS,
  SETTINGS.UPDATE_STORE_DETAILS,
  WALLETS.CAN_MANAGE_WALLET,
  WALLETS.CAN_VIEW_BALANCE,
  SETTINGS.MANAGE_BRANCHES,
  CHOWBOT.CAN_VIEW_TOKEN_BALANCE,
  PAYMENTS.CAN_VIEW_ANALYTICS,
];

const OWNER = [
  ...ADMIN,
  SUBSCRIPTIONS.MANAGE_SUBSCRIPTIONS,
  SUBSCRIPTIONS.VIEW_PAYMENT_HISTORY,
  SETTINGS.UPDATE_SECURITY_PIN,
];

/* PLANS AND PERMISSIONS */

const STARTER = [PLAN_PERMISSIONS.CAN_CUSTOMIZE_LINK, GENERAL_ACCESS];

const BASIC = [
  ...STARTER,
  PLAN_PERMISSIONS.CAN_MANAGE_ITEMS,
  PLAN_PERMISSIONS.CAN_MANAGE_CATEGORIES, 
  PLAN_PERMISSIONS.CAN_MANAGE_DISCOUNTS, 
  PLAN_PERMISSIONS.CAN_MANAGE_PRODUCT_OPTIONS,
  PLAN_PERMISSIONS.CAN_MANAGE_COUPONS,
  RECEIPTS.CAN_GENERATE_RECEIPTS,
  DELIVERIES.CAN_BOOK_DELIVERIES,
  PLAN_PERMISSIONS.CAN_INSTAGRAM_IMPORT,
  PLAN_PERMISSIONS.CAN_CUSTOMIZE_COLORS,
  PRODUCTS.FEATURE_PRODUCTS,
  ORDERS.RECORD_ORDERS,
];

const BUSINESS_PLUS = [
  ...BASIC,
  PLAN_PERMISSIONS.CAN_OWN_TEAM,
  PLAN_PERMISSIONS.CAN_OWN_MULTIPLE_STORES,
  PLAN_PERMISSIONS.CAN_MANAGE_CURRENCIES,
  ORDERS.EXPORT_ORDERS,
  PLAN_PERMISSIONS.CAN_OWN_BRANCHES,
  PLAN_PERMISSIONS.CAN_USE_FACEBOOK_PIXEL,
];

const KITCHEN = [
  ...BUSINESS_PLUS,
  PLAN_PERMISSIONS.CAN_USE_CHOWBOT,
  PLAN_PERMISSIONS.CAN_USE_MENU_UPLOADS,
  PLAN_PERMISSIONS.CAN_USE_CHOWDECK,
];

/* EXPORTS */

const SCOPES = {
  DASHBOARD,
  ORDERS,
  PRODUCTS,
  CUSTOMERS,
  SETTINGS,
  SUBSCRIPTIONS,
  PLAN_PERMISSIONS,
  COUNTRY_PERMISSIONS,
  GENERAL_ACCESS,
  WALLETS,
  RECEIPTS,
  DELIVERIES,
  CHOWBOT,
  PAYMENTS,
};

const RolePermissions = {
  OWNER,
  ADMIN,
  OPERATOR,
};

const PlanPermissions = {
  STARTER,
  BASIC,
  BUSINESS_PLUS,
  KITCHEN,
};

const CountryPermissions = {
  GH: [COUNTRY_PERMISSIONS.CAN_COLLECT_PAYMENTS],
  NG: [COUNTRY_PERMISSIONS.CAN_COLLECT_PAYMENTS, DELIVERIES.CAN_BOOK_DELIVERIES],
};

const routePermissions = {
  "/my-store/team-members": {
    subscription: PLAN_PERMISSIONS.CAN_OWN_TEAM,
    message: "You'll need to be on the business plus plan to add store managers",
  },
  "/my-store/subscription": {
    role: SUBSCRIPTIONS.MANAGE_SUBSCRIPTIONS,
  },
  // "/my-store/delivery-areas": {
  //   role: SETTINGS.UPDATE_STORE_DETAILS,
  // },
  // "/my-store/details": {
  //   role: SETTINGS.UPDATE_STORE_DETAILS,
  // },
  // "/my-store/configurations": {
  //   role: SETTINGS.UPDATE_STORE_DETAILS,
  // },
  // "/my-store/checkout-options": {
  //   role: SETTINGS.UPDATE_STORE_DETAILS,
  // },
  "/orders/create": {
    subscription: ORDERS.RECORD_ORDERS,
    message: "You'll need to be on a paid plan to record orders",
  },
  "/products/create-coupon": {
    subscription: PLAN_PERMISSIONS.CAN_MANAGE_COUPONS,
    message: "You'll need to be on a paid plan to create coupons",
  },
  "/my-store/payments": {
    needsPayments: true,
  },
  "/my-store/chowbot": {
    subscription: PLAN_PERMISSIONS.CAN_USE_CHOWBOT,
    message: "You'll need to be on the Kitchen plan to use chowbot",
  },
  "/chowbot": {
    subscription: PLAN_PERMISSIONS.CAN_USE_CHOWBOT,
    message: "You'll need to be on the Kitchen plan to use chowbot",
  },
  "/payments/invoices": {
    needsPayments: true,
    country: COUNTRY_PERMISSIONS.CAN_COLLECT_PAYMENTS,
  },
  "/payments/invoices/create": {
    needsPayments: true,
    country: COUNTRY_PERMISSIONS.CAN_COLLECT_PAYMENTS,
  },
  "payments/statements": {
    needsPayments: true,
    country: COUNTRY_PERMISSIONS.CAN_COLLECT_PAYMENTS,
  },
  "/deliveries/initiate": {
    needsDeliveries: true,
    // country: DELIVERIES.CAN_BOOK_DELIVERIES,
    // subscription: DELIVERIES.CAN_BOOK_DELIVERIES,
    // message: "You'll need to be on a paid subscription to book deliveries",
  },
  "/deliveries/[slug]": {
    needsDeliveries: true,
    // country: DELIVERIES.CAN_BOOK_DELIVERIES,
    // subscription: DELIVERIES.CAN_BOOK_DELIVERIES,
    // message: "You'll need to be on a paid subscription to book deliveries",
  },
  "/deliveries/initiate/[slug]": {
    needsDeliveries: true,
    // country: DELIVERIES.CAN_BOOK_DELIVERIES,
    // subscription: DELIVERIES.CAN_BOOK_DELIVERIES,
    // message: "You'll need to be on a paid subscription to book deliveries",
  },
  "/deliveries/orders/[slug]": {
    needsDeliveries: true,
    // country: DELIVERIES.CAN_BOOK_DELIVERIES,
    // subscription: DELIVERIES.CAN_BOOK_DELIVERIES,
    // message: "You'll need to be on a paid subscription to book deliveries",
  },
  "/deliveries/initiate/orders/[slug]": {
    needsDeliveries: true,
    // country: DELIVERIES.CAN_BOOK_DELIVERIES,
    // subscription: DELIVERIES.CAN_BOOK_DELIVERIES,
    // message: "You'll need to be on a paid subscription to book deliveries",
  },
};

export type Permission =
  | DASHBOARD
  | ORDERS
  | PRODUCTS
  | CUSTOMERS
  | SETTINGS
  | SUBSCRIPTIONS
  | PLAN_PERMISSIONS
  | COUNTRY_PERMISSIONS
  | WALLETS
  | RECEIPTS
  | DELIVERIES
  | CHOWBOT
  | PAYMENTS;

const actionIsAllowed = (data: {
  permission?: Permission;
  planPermission?: Permission;
  countryPermission?: Permission;
  userRole?: string;
  plan?: string;
  country?: string;
}): boolean => {
  const { userRole, permission, planPermission, plan, country, countryPermission } = data;

  const roleAllows = permission ? RolePermissions[userRole]?.includes(permission) : true;
  const planAllows = planPermission ? PlanPermissions[plan]?.includes(planPermission) : true;
  const countryAllows = countryPermission ? CountryPermissions[country]?.includes(countryPermission) : true;

  return roleAllows && planAllows && countryAllows;
};

const routeIsAllowed = (data: {
  route: string;
  userRole?: string;
  plan?: string;
  hasEnabledPayments?: boolean;
  hasEnabledDeliveries?: boolean;
}) => {
  const { route, userRole, plan, hasEnabledPayments, hasEnabledDeliveries } = data;
  const routePermission = routePermissions[route];

  if (!routePermission) {
    return { allow: true, plan: true, role: true };
  }

  const allowPayments = routePermission?.needsPayments ? routePermission?.needsPayments && hasEnabledPayments : true;
  const allowDeliveries = routePermission?.needsDeliveries
    ? routePermission?.needsDeliveries && hasEnabledDeliveries
    : true;
  const roleAllows = routePermission?.role ? RolePermissions[userRole]?.includes(routePermission?.role) : true;
  const planAllows = routePermission?.subscription
    ? PlanPermissions[plan]?.includes(routePermission?.subscription)
    : true;

  return {
    allow: planAllows && roleAllows && allowPayments && allowDeliveries,
    plan: planAllows,
    role: roleAllows,
    allowPayments,
    message: routePermission?.message,
    allowDeliveries,
  };
};

export { SCOPES, RolePermissions, PlanPermissions, CountryPermissions, actionIsAllowed, routeIsAllowed };
