const routes = {
  userPaths: [
    "/dashboard",
    "/new-dashboard",
    "/add-products",
    "/products",
    "/products/discounts",
    "/products/create-coupon",
    "/products/create-discount",
    "/products/create",
    "/profile",
    "/my-store",
    "/my-store/configurations",
    "/my-store/delivery-areas",
    "/my-store/details",
    "/my-store/categories",
    "/my-store/checkout-options",
    "/my-store/chowbot",
    "/get-verified",
    "/orders",
    "/orders/customers",
    "/orders/carts",
    "/orders/create",
    "/setup/create-store",
    "/setup/add-products",
    "/setup/pick-plan",
    "/setup/success",
    "/setup/progress",
    "/setup/additional-store-details",
    "/deliveries",
    "/deliveries/[slug]",
    "/deliveries/initiate",
    "/deliveries/initiate/orders/[slug]",
    "/deliveries/initiate/[slug]",
    "/deliveries/[slug]/edit",
    "/chowbot",
    "/my-store/team-members",
    "/my-store/change-plan",
    "/my-store/subscription",
    "/my-store/payments",
    "/create-store",
    "/products/[slug]",
    "/orders/[slug]",
    "/products/other-products",
    "/products/coupons",
    "/products/categories",
    "/get-started",
    "/referral-leaderboard",
    "/products/bulk-update-quantities",
    "/products/bulk-update-prices",
    "/get-started/chowbot",
    "/get-started/chowbot/checkout-options",
    "/get-started/chowbot/enable-payments",
    "/get-started/chowbot/delivery-areas",
    "/get-started/chowbot/get-verified",
    "/get-started/chowbot/opening-and-closing-hours",
    "/products/bulk-update-quantities",
    "/products/bulk-update-prices",
    "/products/bulk-update-prices",
    "/payments",
    "/payments/kyc",
    "/payments/statements",
    "/payments/statements/[slug]",
    "/payments/kyc/manual",
    "/payments/invoices/create",
    "/payments/invoices",
    "/payments/request-payment",
    "/payments/payment-links",
    "/payments/payment-links/create",
  ],
  authPaths: ["/login", "/sign-up", "/reset-password", "/join-store"],
};

export default routes;
