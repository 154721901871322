import { COUNTRIES, CountryInterface, KYC_STATUSES } from ".";
import { DELIVERY_PROVIDERS, DeliveryFormAddress, IDeliveryAddress } from "./deliveries";
import { Category } from "./products";
import { Plan, SubscriptionInterface } from "./subscriptions";

export enum StoreRoles {
  OWNER = "OWNER",
  OPERATOR = "OPERATOR",
  ADMIN = "ADMIN",
}

export enum CURRENCIES {
  GHC = "GHS",
  NGN = "NGN",
  USD = "USD",
  EUR = "EUR",
  GBP = "GBP",
  ZAR = "ZAR",
}

export enum INPUT_TYPE {
  TEXT = "text",
  NUMBER = "number",
  RADIO = "radio",
  DROPDOWN = "dropdown",
  TEXTAREA = "textarea",
}

export interface StoreConfiguration {
  view_modes: {
    grid: boolean;
    card: boolean;
    horizontal: boolean;
    default: string;
  };
  hours?: { [key: string]: string } | null;
  fb_pixel?: string;
  ga_id?: string;
  custom_message?: string;
  enquiry_message?: string;
  direct_checkout_enabled?: boolean;
  sort_by_latest_products: boolean;
  show_unavailable_products: boolean;
  customer_pickup_enabled?: boolean;
  whatsapp_checkout_enabled?: boolean;
  require_delivery_info?: boolean;
  color?: string;
  payment_validates_order?: boolean;
  confirm_order_before_payment?: boolean;
  average_delivery_timeline?: string;
  pickup_address?: any;
  send_menu_on_initiation?: boolean;
  menu_images?: string[];
  require_geolocation?: boolean;
  custom_order_success_message?: string;
  require_emails?: boolean;
  payment_timeout?: number;
  auto_customer_check_in?: {
    enabled: boolean;
    days: number;
    message: string;
  };
  custom_checkout_form?: {
    name: string;
    type: INPUT_TYPE;
    is_required: boolean;
    is_enabled?: boolean;
    label?: string;
    options?: string[];
  }[];
}

export interface StorePaymentMethod {
  type: string;
  enabled: boolean;
}

export interface StoreCurrencySettings {
  default: CURRENCIES;
  products: CURRENCIES;
  storefront: CURRENCIES[];
  storefront_default: CURRENCIES;
  rates: { [key: string]: number };
}

export interface BranchItem {
  label: string;
  is_head_branch?: boolean;
  store_id: string;
  store?: {
    name: string;
    logo: string;
    slug: string;
  };
}

export interface Branches {
  id?: string;
  name: string;
  branches: BranchItem[];
}

export interface ChowdeckConfig {
  sync_to_chowdeck: boolean;
  sync_from_chowdeck: boolean;
  auto_delivery: boolean;
  pickup_address?: string;
}

export interface StoreAdditionalDetails {
  business_type: string;
  monthly_orders: string;
  business_category: string;
  social_media_platform: string;
  social_media_username: string;
}

export interface StoreInterface {
  delivery_locations?: string;
  total_visits: number;
  socials?: {
    twitter?: string;
    instagram?: string;
    facebook?: string;
    snapchat?: string;
    whatsapp?: string;
  };
  hero_image?: string;
  logo?: string;
  store_menu?: string;
  country: CountryInterface;
  state?: string;
  address?: string;
  slug: string;
  name: string;
  phone: string;
  secondary_phone?: string;
  description: string;
  owner?: string;
  categories?: Category[];
  createdAt: string;
  updatedAt: string;
  created_at?: string;
  updated_at?: string;
  item_count?: number;
  items_count?: number;
  plan?: Plan;
  configuration: StoreConfiguration;
  id: string;
  delivery_areas: DeliveryArea[] | string[];
  checkout_channels?: CheckoutChannels;
  owners?: {
    user: string;
    role: StoreRoles;
    email: string;
    name: string;
  }[];
  subscription?: SubscriptionInterface | any;
  onboarding_steps?: {
    products_added?: boolean;
    link_added?: boolean;
    test_payment_made?: boolean;
    security_pin_added?: boolean;
  };
  disabled?: boolean;
  payments_enabled?: boolean;
  kyc_approved?: boolean;
  payment_options: StorePaymentMethod[];
  primary_slug?: string;
  slugs?: string[];
  currencies?: StoreCurrencySettings;
  maintenance_mode?: boolean;
  deliveries_enabled?: boolean;
  business_category?: {
    name: string;
    type: string;
    monthly_orders: string;
    product_types: string[];
  };

  extra_info?: {
    delivery_timeline?: string;
    production_timeline?: string;
    refund_policy?: string;
    images_label?: string;
    images?: string[];
  };
  wallet?: string;
  pickup_address?: IDeliveryAddress;
  branches?: string;
  flags?: {
    uses_chowbot?: boolean;
  };
  public_access_tokens: {
    chowdeck?: {
      reference?: string;
    };
  };
  third_party_configs?: {
    chowdeck?: ChowdeckConfig;
  };
  delivery_providers: DELIVERY_PROVIDERS[];
  meta?: {
    instagram_item_upload_count?: number;
  };
}

export interface DeliveryArea {
  id: string;
  name: string;
  fee: number;
}

export interface CheckoutChannels {
  whatsapp: WhatsappCheckoutOption[];
  instagram?: InstagramCheckoutOption;
}

export interface WhatsappCheckoutOption {
  id: string;
  label: string;
  phone?: string;
  type: "WHATSAPP";
  primary?: boolean;
}

export interface InstagramCheckoutOption {
  id: string;
  username: string;
  type: "INSTAGRAM";
  enabled?: boolean;
}

export interface TeamMember {
  id?: string;
  email?: string;
  name?: string;
  role: string;
  user?: string;
  status?: "PENDING" | "ACCEPTED";
}

export enum ID_TYPES {
  "INTERNATIONAL_PASSPORT" = "INTERNATIONAL_PASSPORT",
  "NIN" = "NIN",
  "DRIVERS_LICENSE" = "DRIVERS_LICENSE",
  "VOTERS_CARD" = "VOTERS_CARD",
}

export interface KycStore {
  name: string;
  id: string;
  phone: string;
  slug: string;
}

export interface KYCInfo {
  first_name: string;
  last_name: string;
  phone: string;
  address: {
    address_line1?: string;
    lga?: string;
    state?: string;
    city?: string;
  };
  entity: string;
  bvn: string;
  dob?: string;
  bvn_verified_at?: Date;
  identity: {
    type?: ID_TYPES;
    number?: string;
    url?: string;
    filename?: string;
    verified_at?: Date;
    vnin?: string;
    selfie?: string;
  };
  status: KYC_STATUSES;
  store: string | KycStore;
  created_at: string;
  createdAt: string;
  updated_at: string;
  updatedAt: string;
  id: string;
  bvn_token?: string;
  country?: COUNTRIES;
  verification_method: "INSTANT" | "MANUAL";
  rejection_message?: string;
  proposed_bvn?: {
    bvn: string;
    name: string;
    phone: string;
  };
  phone_verified?: boolean;
}

export enum Tier {
  TIER_1 = "TIER_1",
  TIER_2 = "TIER_2",
  TIER_3 = "TIER_3",
}

export interface ReferredUsers {
  has_claimed: boolean;
  user: { name: string; date_joined: string };
}

export interface Referrals {
  id: string;
  owner: string;
  referral_code: string;
  referrals: ReferredUsers[];
}

export interface ChowdeckItem {
  id: number;
  group_id: number;
  rank: number;
  name: string;
  popular_name: string;
  description: string;
  in_stock: boolean;
  is_published: boolean;
  is_active: boolean;
  price: number;
  currency: string;
  price_description: string;
  created_at: string;
  updated_at: string;
  container_type_id: any;
  menu_group_ids: any;
  reference: string;
  volume_per_portion: number;
  maximum_quantity: any;
  maximum_quantity_as_side: number;
  size_description: any;
  menu_sub_category_id: any;
  listing_price_factor: string;
  container_name: any;
  container_price: any;
  container_description: any;
  container_volume: any;
  tags: ChowdeckTag[];
  category: ChowdeckCategory;
  images: Image[];
}

export interface ChowdeckTag {
  name: string;
  id: number;
}

export interface ChowdeckCategory {
  id: number;
  name: string;
  is_published: boolean;
  is_general: boolean;
  rank?: number;
  reference?: string;
  food_count?: number;
}

export interface Image {
  path: string;
  rank: number;
}
